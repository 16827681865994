<template>
  <div>
    <div v-if="session">
      <h3>{{ $t("events.application.label") }}</h3>
      <div v-if="eventClosed && !userApplication">
        <vca-card class="text-center">
          <div class="bold highlight">
            {{ $t("events.application.event_over_apply") }}
          </div>
        </vca-card>
      </div>
      <div
        v-else-if="
          applicationClosed &&
          (!userApplication ||
            (userApplication && applicationStatus != 'confirmed'))
        "
      >
        <vca-card class="text-center">
          <div class="bold highlight">
            {{ $t("events.application.closed") }}
          </div>
        </vca-card>
      </div>
      <div v-else-if="applicationBefore">
        <vca-card class="text-center">
          <button disabled :title="$t('button.apply')" class="vca-button">
            {{ $t("button.apply") }}
          </button>
          <div class="bold highlight">
            {{ $t("events.application.before") }}
          </div>
        </vca-card>
      </div>
      <div v-else>
        <vca-card v-if="userParticipation && applicationStatus != 'withdrawn'">
          <div v-if="applicationStatus == 'rejected'" class="vca-center">
            <div class="bold highlight">
              {{ $t("events.application.rejected") }}
            </div>
          </div>
          <div v-else-if="!eventClosed" class="vca-center">
            <vca-cancel-button
              @click="withdraw()"
              :title="
                $t('context_button.revoke', {
                  0: $t('events.application.header'),
                })
              "
              :placeholder="
                $t('context_button.revoke', {
                  0: $t('events.application.label'),
                })
              "
            />
          </div>
          <div v-else>
            <vca-card class="text-center">
              <div class="bold highlight">
                {{ $t("events.application.event_over_applied") }}
              </div>
            </vca-card>
          </div>
        </vca-card>
        <div v-else-if="!canApply">
          <vca-card class="text-center">
            <button disabled :title="$t('button.apply')" class="vca-button">
              {{ $t("button.apply") }}
            </button>
            <div class="bold highlight">
              {{ $t("events.application.impossible") }}
            </div>
          </vca-card>
        </div>
        <div v-else-if="!eventClosed">
          <div>
            <h3>{{ $t("events.application.additional.label") }}</h3>
            <vca-textarea
              v-model="value.comment"
              :placeholder="$t('events.application.additional.placeholder')"
            />
          </div>
          <div v-if="userParticipation && applicationStatus == 'withdrawn'">
            <div>{{ $t("events.application.apply_again") }}</div>
            <button
              @click="reapply()"
              :title="$t('button.apply')"
              class="vca-button"
            >
              {{ $t("button.apply") }}
            </button>
          </div>
          <div v-else>
            <button
              @click="apply()"
              :title="$t('button.apply')"
              class="vca-button"
            >
              {{ $t("button.apply") }}
            </button>
          </div>
        </div>
        <div v-else>
          <vca-card class="text-center">
            <div class="bold highlight">
              {{ $t("events.application.event_over_apply") }}
            </div>
          </vca-card>
        </div>
      </div>
    </div>
    <div v-else>
      <vca-card class="text-center" v-if="eventClosed">
        <div class="bold highlight">
          {{ $t("events.application.event_over_apply") }}
        </div>
      </vca-card>

      <vca-card class="text-center" v-else-if="applicationClosed">
        <div class="bold highlight">
          {{ $t("events.application.closed") }}
        </div>
      </vca-card>

      <vca-row>
        <div>
          <button
            @click="register()"
            :title="
              applicationClosed
                ? $t('events.button.register_apply_closed')
                : $t('events.button.register_apply')
            "
            class="vca-button"
          >
            <span v-if="applicationClosed">{{
              $t("events.button.register_apply_closed")
            }}</span>
            <span v-else>{{ $t("events.button.register_apply") }}</span>
          </button>
        </div>
        <div>
          <button
            @click="login()"
            :title="$t('events.button.login')"
            class="vca-button"
          >
            {{ $t("events.button.login") }}
          </button>
        </div>
      </vca-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EventApplicationParticipation",
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      session: "session",
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
      user_participations: "events/participations/list",
    }),
    create: {
      set(value) {
        this.$store.commit("events/participations/create", value);
      },
      get() {
        return this.$store.state.events.participations.create;
      },
    },
    value: {
      set(value) {
        this.userApplication
          ? (this.userApplication = value)
          : (this.create = value);
      },
      get() {
        return this.userApplication ? this.userApplication : this.create;
      },
    },
    canApply() {
      return this.user.profile.birthdate != 0 && this.user.profile.phone != "";
    },
    applicationStatus() {
      return this.userApplication.status;
    },
    userApplication() {
      return this.user_participations
        ? this.user_participations.find((el) => el.event_id == this.event.id)
        : null;
    },
    userParticipation() {
      return (
        this.session && this.user_participations && this.userApplication != null
      );
    },
    applicationBefore() {
      return new Date() < new Date(this.event.application.start_date * 1000);
    },
    applicationClosed() {
      return new Date() > new Date(this.event.application.end_date * 1000);
    },
    eventClosed() {
      return (
        new Date() > new Date(this.event.end_at * 1000) ||
        this.event.event_state.state === "closed" ||
        this.event.event_state.state === "finished"
      );
    },
  },
  methods: {
    reloadParticipations() {
      let data = { user_id: this.user.id };
      this.$store
        .dispatch({
          type: "events/participations/listUser",
          data: data,
        })
        .then((response) => {
          this.$store.commit(
            "events/participations/list",
            !response || response.length == 0 ? [] : response
          );
        });
      if (
        this.hasSystemPermission() ||
        this.hasPoolPermission(this.poolEventPermissions)
      ) {
        this.$store.dispatch({
          type: "events/pg/request",
          data: { initial: true },
        });
      }
    },
    apply() {
      if (!confirm(this.$t("events.application.messages.apply"))) {
        return false;
      }
      this.$store.dispatch({ type: "events/participations/apply" }).then(() => {
        this.reloadParticipations();
      });
      this.$emit("close");
    },
    register() {
      this.$router.push({ path: "/register" });
    },
    login() {
      this.$router.push({ path: "/login" });
    },
    withdraw() {
      if (!confirm(this.$t("events.application.messages.withdraw"))) {
        return false;
      }
      this.$store.commit("events/participations/current", this.userApplication);
      this.$emit("close");
      this.$store
        .dispatch({ type: "events/participations/withdraw" })
        .then(() => {
          this.reloadParticipations();
        });
    },
    reapply() {
      if (!confirm(this.$t("events.application.messages.reapply"))) {
        return false;
      }
      this.$store.commit("events/participations/current", this.userApplication);
      this.$emit("close");
      this.$store
        .dispatch({ type: "events/participations/reapply" })
        .then(() => {
          this.reloadParticipations();
        });
    },
  },
};
</script>
