<template>
  <div>
    <div class="desktop-view vca-right">
      <vca-row>
        <div>
          <button
            :title="$t('button.edit')"
            class="vca-button-small"
            @click="setCurrentEdit(event)"
            v-if="editable"
          >
            {{ $t("button.edit") }}
          </button>
        </div>
        <div v-if="showApplication && event.isPublished">
          <button
            :title="$t('button.participations')"
            class="vca-button-small"
            @click="setCurrentParticipation(event)"
          >
            {{ $t("button.participations") }}
          </button>
        </div>
        <div v-if="event.isPublished">
          <button
            @click="setCurrentEmail(event)"
            :title="$t('context_button.send', { 0: $t('email.label') })"
            class="vca-button-small"
          >
            {{ $t("context_button.send", { 0: $t("email.label") }) }}
          </button>
        </div>
      </vca-row>
    </div>
    <div class="mobile-block-view vca-center">
      <vca-row>
        <button
          :title="$t('button.edit')"
          class="vca-button-small"
          @click="setCurrentEdit(event)"
        >
          {{ $t("button.edit") }}
        </button>
        <button
          v-if="showApplication && event.isPublished"
          :title="$t('button.participations')"
          class="vca-button-small"
          @click="setCurrentParticipation(event)"
        >
          {{ $t("button.participations") }}
        </button>
        <button
          v-if="event.isPublished"
          @click="setCurrentEmail(event)"
          :title="$t('context_button.send', { 0: $t('email.label') })"
          class="vca-button-small"
        >
          {{ $t("context_button.send", { 0: $t("email.label") }) }}
        </button>
      </vca-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EventApplicationManage",
  props: {
    event: {
      type: Object,
      default: null,
    },
    showApplication: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
  },
  methods: {
    isEventAsp(value) {
      return (
        this.user && value.event_asp_id && this.user.id == value.event_asp_id
      );
    },
    setCurrentEdit(value) {
      this.$router.push({ path: "/events/edit/" + value.id });
    },
    setCurrentParticipation(value) {
      this.$router.push({ path: "/events/participants/" + value.id });
    },
    setCurrentEmail(event) {
      if (this.isEventAsp(event)) {
        this.$store.commit("user/emails/mailbox/message/create_event", event);
        this.$router.push({ path: "/account/asps/emails/create" });
      } else {
        this.$store.commit("emails/mailbox/message/create_event", event);
        this.$router.push({ path: "/emails/create" });
      }
    },
  },
};
</script>
