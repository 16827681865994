<template>
  <div>
    <div class="table-container">
      <table class="table-desktop">
        <thead>
          <table-header-pagination
            :placeholder="$t('events.list.search')"
            :store="store"
          />
        </thead>
        <tbody>
          <table-border />
          <table-content-prev :store="store" />
          <table-border />
        </tbody>
      </table>
      <table class="table-mobile">
        <thead>
          <table-search
            :store="store"
            :placeholder="$t('events.list.search')"
          />
        </thead>
        <tbody>
          <table-border />
          <table-content-prev :store="store" />
          <table-border />
        </tbody>
      </table>
    </div>
    <vca-card class="vca-border" v-if="is_loading">
      <Spinner />
      <br />
      <h3 class="text-center">{{ getLoadingMessage() }}</h3>
      <br />
    </vca-card>
    <div v-else-if="list.length > 0" class="cards-container">
      <div v-for="event in list" :key="event.id">
        <CrewEventCard
          v-if="event.type_of_event == 'crew_meeting'"
          :event="event"
        />
        <EventCard v-else :event="event" />
      </div>
    </div>
    <vca-card class="text-center" v-else>
      <vca-card class="primary-dark bold vca-border">
        {{ $t("table.page.no_results") }}
      </vca-card>
    </vca-card>
    <div>
      <div class="table-container">
        <table class="table-desktop">
          <tbody>
            <table-border />
            <table-content-next :store="store" />
            <table-border />
          </tbody>
        </table>
        <table class="table-desktop">
          <thead>
            <table-bottom-pagination :store="store" />
          </thead>
        </table>

        <table class="table-mobile">
          <table-border />
          <table-content-next :store="store" />
          <table-border />
        </table>
        <table class="table-mobile">
          <thead>
            <table-bottom-pagination :store="store" />
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import CrewEventCard from "@/components/events/list/CrewEventCard";
import EventCard from "@/components/events/list/EventCard";
import { mapState } from "vuex";
import Spinner from "@/components/utils/Spinner.vue";
export default {
  name: "EventCards",
  components: { Spinner, EventCard, CrewEventCard },
  data() {
    return {
      store: "events/pg",
    };
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    is_loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
  },
  methods: {
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("events/application/current", null);
      } else {
        this.$store.commit("events/application/current", value);
      }
    },
    sort(val) {
      this.$emit("sort", val);
    },
    getLoadingMessage() {
      return this.$t("events.list.loading." + Math.floor(Math.random() * 11));
    },
  },
};
</script>
<style lang="scss">
.cards-container {
  display: grid;

  @include media(large) {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  & > div {
    box-sizing: border-box;
    height: 100%;
    & > div {
      height: 100%;
      box-sizing: border-box;
      padding: 5px 0;
      @include media(large) {
        padding: 10px;
      }

      & > div {
        padding: 10px;
      }
    }
  }
}
</style>
